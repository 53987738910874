import React from 'react';
import SEO from '../components/seo/SEO';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


export default function Environment () { 

  const images = useStaticQuery(graphql`
    query {
      bkg: file(relativePath: {eq: "environment/bkg--environment-text.png"}) {
        childImageSharp {
          fixed(width: 1920, height: 1080, quality: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      frg: file(relativePath: {eq: "environment/frg--environment.jpg"}) {
        childImageSharp {
          fixed(width: 1346, height: 1080, quality: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }  
  `);

  return (
    <>
      <SEO title='Environment' />
      <Img fixed={ images.bkg.childImageSharp.fixed } alt='Environment' />
      <Img style={ { position: 'absolute', left: '574px' } } fixed={ images.frg.childImageSharp.fixed } alt='Environment' />
    </>
  );

}
